import { userService } from "../../helpers/auth.service";

const token = localStorage.getItem("token");

export const state = token
  ? { status: true, token }
  : { status: false, user: null, token: null };

export const getters = {
  status: state => state.status,
  user: state => state.user,
  token: state => state.token
}

export const mutations = {
  loginSuccess (state, { user, token }) {
    state.status = true;
    state.user = user;
    state.token = token
  },
  loginFailure (state) {
    state.status = false;
    state.user = null;
  },
  logout (state) {
    state.status = false;
    state.token = null;
    state.user = null;
  },
};

export const actions = {

  // Logs in the user.
  async login ({ commit }, { email, password }) {
    const res = await userService.login(email, password)
    commit("loginSuccess", { user: res.user, token: res.token });
  },

  // Validate user token
  async validate () {
    try {
      await userService.validate();
      return true;
    } catch (error) {
      if (error.response && error.response.data) {
        console.error(error.response.data.message)
      }
      return false;
    }
  },

  // Logout the user
  async logout ({ commit }) {
    await userService.logout();
    commit("logout");
  },
};
