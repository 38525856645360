import axios from 'axios'
import config from '../constants/config'

export const userService = {
  login,
  logout,
  validate
};

async function login (username, password) {
  const res = await axios.post(`${config.BASE_URL}${config.API_ROUTES.AUTH.LOGIN}`, { username, password })
  if (res.data.token.access.token) {
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + res.data.token.access.token
    axios.defaults.headers.common['subscriptionkey'] = res.data.subscriptionKey
    localStorage.setItem("token", res.data.token.access.token);
    localStorage.setItem("user", JSON.stringify(res.user));
  }

  return { user: res.data.user, token: res.data.token.access.token };
}

async function validate () {
  const token = localStorage.getItem('token')
  if (!token || token == 'undefined') throw new Error('Session expired. Please login again.')

  axios.defaults.headers['Authorization'] = 'Bearer ' + token
  await axios.post(`${config.BASE_URL}${config.API_ROUTES.AUTH.SESSION}`)
}


async function logout () {
  const user = localStorage.getItem('user')
  if (user && user != 'undefined') {
    let parseUser = JSON.parse(user)
    await axios.post(`${config.BASE_URL}${config.API_ROUTES.AUTH.LOGOUT}`, { id: parseUser.id })
  }

  localStorage.removeItem("token");
  localStorage.removeItem("user");
}
