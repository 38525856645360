import { createStore } from 'vuex'
import createPersistedState from "vuex-persistedstate";
import modules from './modules'

const store = createStore({
  modules,
  strict: process.env.NODE_ENV !== 'production',
  plugins: [createPersistedState()]
})

export default store

