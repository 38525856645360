import store from "@/state/store";

export default [
  {
    path: "/",
    name: "dashboard",
    meta: { authRequired: true },
    component: () => import("../views/dashboard"),
  },
  {
    path: "/login",
    name: "login",
    component: () => import("../views/login"),
    meta: {
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters["auth/status"]) {
          // Redirect to the home page instead
          next({ name: "dashboard" });
        } else {
          // Continue to the login page
          next();
        }
      },
    },
  },
  {
    path: "/forgot-password",
    name: "Forgot password",
    component: () => import("../views/forgot-password"),
    meta: {
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters["auth/status"]) {
          // Redirect to the home page instead
          next({ name: "dashboard" });
        } else {
          // Continue to the login page
          next();
        }
      },
    },
  },
  {
    path: "/logout",
    name: "logout",
    component: () => import("../views/logout"),
    meta: {
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        store.dispatch("auth/logout");
        const authRequiredOnPreviousRoute = routeFrom.matched.some((route) =>
          route.push("/login")
        );
        next(
          authRequiredOnPreviousRoute ? { name: "dashboard" } : { ...routeFrom }
        );
      },
    },
  },
  {
    path: "/404",
    name: "404",
    component: require("../views/404").default,
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "404",
  },
  {
    path: "/customers",
    name: "customers",
    meta: { authRequired: true },
    component: () => import("@/views/clients"),
  },
  {
    path: "/clients/add-new-client",
    name: "create-new-client",
    meta: { authRequired: true },
    component: () => import("@/components/clients/add-new-client"),
  },
  {
    path: "/customer/:id",
    name: "customerDetails",
    meta: { authRequired: true },
    component: () => import("@/components/clients/customer"),
  },
  {
    path: "/licenses",
    name: "licenses",
    meta: { authRequired: true },
    component: () => import("@/views/licenses"),
  },
  {
    path: "/licenses/generate-license",
    name: "generate-license",
    meta: { authRequired: true },
    component: () => import("@/components/licenses/generate-license"),
  },
  {
    path: "/reports",
    name: "reports",
    meta: { authRequired: true },
    component: () => import("@/views/reports"),
  }, {
    path: "/users",
    name: "users",
    meta: { authRequired: true },
    component: () => import("@/views/users.vue"),
  }
];
