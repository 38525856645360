// exports.CLIENT_URL = "http://localhost:8082";
// exports.BASE_URL = "http://localhost:5001";
// exports.SOCKET_URL = "http://localhost:5001";

exports.CLIENT_URL = "https://bo.grillbills.com";
exports.BASE_URL = "https://bo.grillbills.com/server";
exports.SOCKET_URL = "https://bo.grillbills.com/server";

exports.SUBSCRIPTION_KEY = "thisismysubscriptionkey"

exports.API_ROUTES = {
  AUTH: {
    LOGIN: "/api/auth/login",
    LOGOUT: "/api/auth/logout",
    SESSION: "/api/auth/session",
  },
  CLIENTS: {
    GET: "/api/customers/",
    CREATE: "/api/customers/",
    CLIENT_BY_ID: "/api/customers/",
    count: "/api/customers/count",
    status: "/api/customers/status",
    GetCustomerBySalesMan: '/api/customers/getAllCustomerBYSalesManId/'
  },
  LICENSES: {
    GET: "/api/licenses/",
    GENERATE: "/api/licenses/",
    BLOCK: "/api/licenses/blockLicense",
    DeleteLicense: "/api/licenses/"
  },
  USERS: {
    GET: "/api/user",
    CREATE: "/api/user",
    GETALLROLES: "/api/user/getAllRoles",
    CHANGESTATUS: "/api/user/changeStatus",
  }
};
